import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const BannerImage = ({ imageInfo, children }) => {
  const imageClasses = '' // Add styling to all images here.
  const {
    alt = '',
    childImageSharp,
    desktopimage,
    // tabletimage,
    mobileimage,
  } = imageInfo

  // if (
  //   !!desktopimage &&
  //   !!desktopimage.childImageSharp &&
  //   !!tabletimage &&
  //   !!tabletimage.childImageSharp
  // ) {
  //   const sources = [
  //     desktopimage.childImageSharp.fluid,
  //     {
  //       ...tabletimage.childImageSharp.fluid,
  //       media: `(max-width: 850px)`,
  //     },
  //     {
  //       ...mobileimage.childImageSharp.fluid,
  //       media: `(max-width: 500px)`,
  //     },
  //   ];

  //   return (
  //     <div>
  //       <Img
  //         className={`gatsby-banner${imageClasses}`}
  //         fluid={sources}
  //         alt={alt}
  //       />
  //       <div className="absolute bottom-0 md/lg:top-0 w-full">{children}</div>
  //     </div>
  //   );
  // }

  if (!!desktopimage && !!desktopimage.childImageSharp) {
    const sources = [
      mobileimage.childImageSharp.fluid,
      {
        ...desktopimage.childImageSharp.fluid,
        media: `(min-width: 834px)`,
      },
    ]

    return (
      <div>
        <Img
          className={`gatsby-banner${imageClasses}`}
          fluid={sources}
          alt={alt}
        />
        <>{children}</>
      </div>
    )
  }

  if (!!desktopimage && typeof desktopimage === 'string')
    return (
      <div>
        <img
          className={`flex flex-row-reverse ${imageClasses}`}
          src={desktopimage}
          alt={alt}
        />
        <div className="absolute bottom-0 md/lg:top-0 w-full">{children}</div>
      </div>
    )

  console.log(
    'Banner Image tag is broken... Look into why in BannerImage component.'
  )
  return null
}

BannerImage.propTypes = {
  imageInfo: PropTypes.shape({
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
  children: PropTypes.object,
}

export default BannerImage
